<template>
</template>

<script>
    export default {
        data() {
            return {
                inviteCode: ''
            }
        },
        created() {
            let query = this.$route.query
            this.inviteCode = query.invite_code || ''
            const body_width = document.body.clientWidth
            if (body_width <= 800) {
                this.to('/h5/register',this.inviteCode)
            } else {
                this.to('/register',this.inviteCode)
            }
        },
        methods: {
            to(path,invite_code) {
                this.$router.replace({
                    path,
                    query: {
                        invite_code
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
</style>